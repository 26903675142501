<template>
  <div class="nylas-callback">
    <!-- Display a message or redirect the user once the process is complete -->
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "NylasCallback",
  data() {
    return {
      message: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setNylasAccount"]),
    async exchangeCodeForToken() {
      const query = this.$route.query;
      console.log(query);

      if (query.code) {
        try {
          const result =  await axios.post("/api/nylas/authorize", {
            code: query.code,
            userId: this.user._id,
            redirectUri: window.location.origin + "/nylas/callback",
          });

          this.setNylasAccount({
            grantId: result.data.grantId,
          });
          this.$router.push("/calendar");
          this.toastSuccess(
            this.$t("ConnectedExternalCalendar")
          );
        } catch (error) {
          console.error(error);
          this.toastError(this.$t("ErrorAdding"));
          await this.logError("[Microsoft] Login", error);
        }
      }
    },
  },
  created() {
    this.exchangeCodeForToken();
  },
};
</script>

<style scoped>
/* Add your styles here */
.nylas-callback {
  /* Style your component */
}
</style>
