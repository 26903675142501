var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nylas-callback"
  }, [_vm.message ? _c('p', [_vm._v(_vm._s(_vm.message))]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }